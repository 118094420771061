import cx from 'classnames'
import { Signup } from 'services/data/queries.graphql'
import Footer from 'components/footer'
import OpenGraph from 'components/open_graph'
import { request } from 'graphql-request'
import React, { useCallback, useEffect, useState } from 'react'
import isEmail from 'sane-email-validation'
import styles from 'styles/reserve.module.scss'

async function signup(email, username) {
  return request(process.env.NEXT_PUBLIC_API_URL, Signup, { email, username })
}

function SignupForm({ className, onDone }) {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onChangeEmail = useCallback(event => {
    setEmail(event.target.value)
  }, [])

  const onChangeUsername = useCallback(event => {
    let value = event.target.value
    value = value.replace(/\s+/g, '-')
    setUsername(value)
  }, [])

  const isValid = /^[a-zA-Z0-9_-]+$/.test(username) && isEmail(email)

  const onSubmit = useCallback(
    async event => {
      event.preventDefault()
      setIsSubmitting(true)
      try {
        await signup(email, username)
        onDone()
      } finally {
        setIsSubmitting(false)
      }
      // TODO(ibash) handle errors
    },
    [email, username]
  )

  return (
    <form className={className} onSubmit={onSubmit}>
      <OpenGraph title="Signup for Summon" />
      <div className={styles.usernameContainer}>
        <span>summon.me/</span>
        <input
          autoFocus
          className={styles.input}
          disabled={isSubmitting}
          onChange={onChangeUsername}
          placeholder="your-link"
          spellCheck="false"
          value={username}
        />
      </div>
      <input
        className={styles.input}
        disabled={isSubmitting}
        onChange={onChangeEmail}
        placeholder="Enter your email..."
        type="email"
        value={email}
      />
      <button disabled={!isValid || isSubmitting}>Reserve</button>
    </form>
  )
}

function Done({ className }) {
  // fix for transition firing on page load
  const [style, setStyle] = useState({ display: 'none' })
  useEffect(() => {
    setStyle({})
  }, [])

  return (
    <div className={cx(styles.done, className)} style={style}>
      <p>Thank you, we've noted your reservation. See you soon :)</p>
    </div>
  )
}

export default function Reserve() {
  const [isDone, setIsDone] = useState(false)

  const onDone = useCallback(() => {
    setIsDone(true)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.logoType}>Summon</div>
        <div className={styles.content}>
          <SignupForm
            onDone={onDone}
            className={cx({
              [styles.visible]: !isDone,
              [styles.hidden]: isDone
            })}
          />
          <Done
            className={cx({
              [styles.visible]: isDone,
              [styles.hidden]: !isDone
            })}
          />
        </div>
      </div>
      <Footer light />
    </div>
  )
}
