import cx from 'classnames'
import React from 'react'
import styles from 'styles/footer.module.scss'

export default function Footer({ light }) {
  return (
    <footer className={cx(styles.container, { [styles.light]: light })}>
      <div className={styles.strike}>
        <hr className={styles.hr} />
        <div>&#169;2021 Summon</div>
      </div>
      <div className={styles.links}>
        <a href="https://summon.me">Signup</a>
        <a href="mailto:hello@summon.app">Contact</a>
        <a href="mailto:one-of-us@summon.app">Jobs</a>
        <a href="https://summon.app/privacy">Privacy</a>
        <a href="https://summon.app/terms">Terms</a>
      </div>
    </footer>
  )
}
